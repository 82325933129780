import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

import { colors } from "../../../designSystem/colors";
import { BackgroundLayer } from "../../../model/hideout";
import CustomizationContent from "../../Modal/HideoutCustomization/CustomizationContent";
import PhaserGame from "../../../PhaserGame";
import HideoutScene from "../../../scenes/HideoutScene";
import useScreenSize from "../../../hooks/useScreenSize";
import { layerCanCustomizeColor } from "../../../utils/backgroundLayer";
import useHideoutLayerCustomization from "../../../hooks/useHideoutLayerCustomization";

const MAIN_CONTENT_WIDTH_RATIO = 700 / 520;

const FloatingContainer = styled(motion.div).attrs({ className: "d-flex" })<{
  $show: boolean;
}>`
  position: absolute;
  right: 15vh;
  top: 50%;
  height: 50vh;
  width: calc(50vh * ${MAIN_CONTENT_WIDTH_RATIO});
  pointer-events: ${(props) => (props.$show ? "unset" : "none")};
`;

const ContentContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${colors.modalBackground};
  border: 0.3vh solid ${colors.primaryRed};
  border-radius: 1.6vh;
  padding: 1.6vh;
`;

interface LayerCustomizationContentProps {
  show: boolean;
  layer: BackgroundLayer | undefined;
}

const LayerCustomizationContent: React.FC<LayerCustomizationContentProps> = ({
  show,
  layer,
}) => {
  const { width, height } = useScreenSize();
  const { hideoutLayer } = useHideoutLayerCustomization();

  /**
   * Whenever this content is show, we add a transparent layer behind to stop click propagation
   */
  useEffect(() => {
    const hideoutScene = PhaserGame.scene.getScene("hideout") as HideoutScene;
    if (show)
      hideoutScene.addStopPropagationLayer("menuModel", {
        x: width - (height * 0.17 + height * 0.5 * MAIN_CONTENT_WIDTH_RATIO),
        y: (height * 0.5) / 2,
        height: height * 0.5,
        width: height * 0.5 * MAIN_CONTENT_WIDTH_RATIO,
      });
    else hideoutScene.removeStopPropagationLayer("menuModel");
  }, [show, width, height]);

  const showColorCustomization = useMemo(
    () => layer && layerCanCustomizeColor(layer, hideoutLayer[layer]!),
    [hideoutLayer, layer]
  );

  /**
   * Whenever color customization is show, we add a transparent layer behind to stop click propagation
   */
  useEffect(() => {
    const hideoutScene = PhaserGame.scene.getScene("hideout") as HideoutScene;
    if (showColorCustomization)
      hideoutScene.addStopPropagationLayer("colorSelection", {
        x: width - (height * 0.17 + height * 0.5 * MAIN_CONTENT_WIDTH_RATIO),
        y: (height * 0.5) / 2 + height * 0.508,
        height: (0.006 + 0.048 + 0.01 + 0.1) * height,
        width: height * 0.5 * MAIN_CONTENT_WIDTH_RATIO,
      });
    else hideoutScene.removeStopPropagationLayer("colorSelection");
  }, [showColorCustomization, width, height]);

  return (
    <AnimatePresence exitBeforeEnter>
      <FloatingContainer
        key={layer}
        $show={show}
        initial={{ x: -50, opacity: 0, y: "-50%" }}
        animate={{
          x: show ? 0 : -50,
          opacity: show ? 1 : 0,
          y: "-50%",
        }}
        exit={{ x: -50, opacity: 0, y: "-50%" }}
        transition={{ type: "keyframes", duration: 0.2 }}
        onClick={(event) => event.stopPropagation()}
      >
        <ContentContainer onClick={(event) => event.stopPropagation()}>
          {layer && <CustomizationContent layer={layer} />}
        </ContentContainer>
      </FloatingContainer>
    </AnimatePresence>
  );
};

export default LayerCustomizationContent;
