import React, { useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { useGlobalState } from "../../../store/store";
import {
  Diamond,
  HammerIcon,
  HideoutBackgroundIcon,
  HideoutFloorIcon,
  HideoutHouseboxIcon,
  HideoutLayoutIcon,
  HideoutLiningIcon,
  HideoutWallpaperIcon,
  SVGPropsWithColor,
} from "../../../assets/icons";
import { colors } from "../../../designSystem/colors";
import { PrimaryText } from "../../../designSystem";
import { clickable } from "../../Common/style";
import LayerCustomizationContent from "./LayerCustomizationContent";
import { BackgroundLayer } from "../../../model/hideout";
import PhaserGame from "../../../PhaserGame";
import HideoutScene from "../../../scenes/HideoutScene";
import { playSecondaryClickSound } from "../../../utils/sound";

const EditMenuContainer = styled(motion.div)<{ $show: boolean }>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 2vh;
  display: flex;
  flex-direction: column;
  ${(props) => (props.$show ? "" : "pointer-events: none;")}
`;

const IconContainer = styled.div.attrs((props) => ({
  className: `${props.className} d-flex align-items-center justify-content-center`,
}))`
  height: 4vh;
  width: 4vh;
`;

const Divider = styled.div`
  width: 4vh;
  border: 0.5px solid ${colors.primaryRed};
  margin-top: 1vh;
  margin-bottom: 1vh;
`;

const MenuSelectionContainer = styled.div.attrs({
  className: "d-flex position-relative align-items-center",
  onMouseDown: playSecondaryClickSound,
})<{ active: boolean }>`
  ${clickable}
  margin-top: 1vh;

  .menu-content {
    transform: translateX(0);
    transition: 0.25s all ease;
  }

  .diamond {
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: 0.25s all ease;
  }

  &:hover {
    .menu-content {
      transform: translateX(-0.5vh);
    }

    .diamond {
      opacity: 1;
    }
  }
`;

const MenuSelectionIconContainer = styled(IconContainer).attrs((props) => ({
  className: `${props.className} menu-content`,
}))`
  background: ${colors.primaryRed};
  border-radius: 100px;
  padding: 0.8vh;
  margin-right: 1.5vh;
`;

const MenuSelectionDiamondContainer = styled.div`
  position: absolute;
  left: -2.5vh;
`;

const EditModeMenu = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [mode] = useGlobalState("mode");
  const [activeMenu, setActiveMenu] = useGlobalState("editModeActiveLayer");

  /**
   * Reset active menu when exit edit mode
   */
  useEffect(() => {
    if (mode !== "edit" && activeMenu) {
      setActiveMenu(undefined);
    }
  }, [activeMenu, mode, setActiveMenu]);

  const containerAnimateProps = useMemo(() => {
    switch (mode) {
      case "edit":
        return {
          opacity: 1,
          y: "-50%",
          x: 0,
        };
      default:
        return {
          opacity: 0,
          y: "-50%",
          x: -50,
        };
    }
  }, [mode]);

  const renderMenuItem = useCallback(
    (
      layer: BackgroundLayer,
      Icon: React.FC<SVGPropsWithColor>,
      title: string
    ) => (
      <MenuSelectionContainer
        active={activeMenu === layer}
        onClick={() =>
          setActiveMenu((_activeMenu) =>
            _activeMenu === layer ? undefined : layer
          )
        }
      >
        <MenuSelectionIconContainer>
          <Icon
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "auto",
              height: "auto",
            }}
          />
        </MenuSelectionIconContainer>
        <PrimaryText fontSize="1.5vh" className="menu-content">
          {title}
        </PrimaryText>

        <MenuSelectionDiamondContainer className="diamond">
          <Diamond width="1vh" height="auto" />
        </MenuSelectionDiamondContainer>
      </MenuSelectionContainer>
    ),
    [activeMenu, setActiveMenu]
  );

  return (
    <EditMenuContainer
      ref={containerRef}
      $show={mode === "edit"}
      initial={{ opacity: 0 }}
      animate={containerAnimateProps}
      transition={{
        type: "keyframes",
      }}
      onAnimationComplete={() => {
        const hideoutScene = PhaserGame.scene.getScene(
          "hideout"
        ) as HideoutScene;
        const editMenuContainer = containerRef.current;
        if (editMenuContainer && mode === "edit") {
          const { left, top, height, width } =
            editMenuContainer.getBoundingClientRect();
          hideoutScene.addStopPropagationLayer("editMenu", {
            x: left,
            y: top,
            height,
            width,
          });
        } else {
          hideoutScene.removeStopPropagationLayer("editMenu");
        }
      }}
    >
      {/* Hammer Logo */}
      <IconContainer>
        <HammerIcon height="100%" width="auto" />
      </IconContainer>

      <Divider />

      {/* Menu Selection */}
      {renderMenuItem("layouttop", HideoutLayoutIcon, "layout")}
      {renderMenuItem("liningwood", HideoutLiningIcon, "lining")}
      {renderMenuItem("wallpaper", HideoutWallpaperIcon, "walls")}
      {renderMenuItem("floor", HideoutFloorIcon, "flooring")}
      {renderMenuItem("housebox", HideoutHouseboxIcon, "box")}
      {renderMenuItem("bg", HideoutBackgroundIcon, "mood")}

      <LayerCustomizationContent
        show={Boolean(activeMenu)}
        layer={activeMenu}
      />
    </EditMenuContainer>
  );
};

export default EditModeMenu;
